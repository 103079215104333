// Copyright © 2021 The Things Industries B.V.

import React, { useState, useCallback } from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import AzureIoTHubImage from '@assets/misc/azure-iot-hub-logo.png'
import tts from '@console/api/tts'

import PageTitle from '@ttn-lw/components/page-title'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import DataSheet from '@ttn-lw/components/data-sheet'
import Button from '@ttn-lw/components/button'
import Link from '@ttn-lw/components/link'

import ErrorView from '@ttn-lw/lib/components/error-view'
import Message from '@ttn-lw/lib/components/message'

import AzureIoTHubIntegrationForm from '@console/containers/azure-iot-hub-integration-form/index.tti'

import Require from '@console/lib/components/require'

import SubViewError from '@console/views/sub-view-error'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectAsConfig } from '@ttn-lw/lib/selectors/env'

import { mayViewOrEditApplicationPackages } from '@console/lib/feature-checks'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'

import style from './application-integrations-azure-iot-hub.tti.styl'

const m = defineMessages({
  azureIoTHubIntegration: 'Azure IoT Hub integration',
  applicationId: 'Application ID',
  clusterAddress: 'Cluster address',
  apiKey: 'API key',
  goToApiKeys: 'Go to API keys',
  credentials: 'Credentials',
  generateApiKey: 'Generate API Key',
  configuration: 'Configuration',
  deploymentGuide: 'Deployment Guide',
  infoText: `The Azure IoT Hub integration supports streaming data, creating end devices and device twins. This integration comes with an Azure Resource Manager template to deploy in your Azure account.`,
})

const AzureIoTHubIntegration = () => {
  const appId = useSelector(selectSelectedApplicationId)

  useBreadcrumbs(
    'apps.single.integrations.azure-iot-hub',
    <Breadcrumb
      path={`/applications/${appId}/integrations/azure-iot-hub`}
      content={sharedMessages.azureIoTHub}
    />,
  )

  const asConfig = useSelector(selectAsConfig)

  const [key, setKey] = useState(undefined)

  const handleGeneratePasswordClick = useCallback(async () => {
    const key = {
      name: `azure-iot-hub-${Date.now()}`,
      rights: [
        'RIGHT_APPLICATION_DEVICES_READ',
        'RIGHT_APPLICATION_DEVICES_WRITE',
        'RIGHT_APPLICATION_DEVICES_READ_KEYS',
        'RIGHT_APPLICATION_DEVICES_WRITE_KEYS',
        'RIGHT_APPLICATION_TRAFFIC_DOWN_WRITE',
      ],
    }
    const result = await tts.Applications.ApiKeys.create(appId, key)

    setKey(result)
  }, [appId])

  const connectionData = [
    {
      header: '',
      items: [
        {
          key: m.applicationId,
          type: 'code',
          sensitive: false,
          value: appId,
        },
        {
          key: m.clusterAddress,
          type: 'code',
          sensitive: false,
          value: asConfig.base_url,
        },
      ],
    },
  ]

  if (key) {
    connectionData[0].items.push({
      key: m.apiKey,
      type: 'code',
      value: key.key,
    })
  } else {
    connectionData[0].items.push({
      key: m.apiKey,
      value: (
        <React.Fragment>
          <Button
            className={style.generateApiKeyButton}
            message={m.generateApiKey}
            onClick={handleGeneratePasswordClick}
          />
          <Link to={`/applications/${appId}/api-keys`} secondary>
            <Message content={m.goToApiKeys} />
          </Link>
        </React.Fragment>
      ),
    })
  }

  return (
    <Require
      featureCheck={mayViewOrEditApplicationPackages}
      otherwise={{ redirect: `/applications/${appId}` }}
    >
      <ErrorView ErrorComponent={SubViewError}>
        <Container>
          <PageTitle title={m.azureIoTHubIntegration} />
          <Row>
            <Col lg={8} md={12}>
              <img className={style.logo} src={AzureIoTHubImage} alt={sharedMessages.azureIoTHub} />
              <Message content={m.infoText} className={style.info} />
              <div>
                <Message
                  component="h4"
                  content={sharedMessages.furtherResources}
                  className={style.furtherResources}
                />
                <Link.DocLink path="/integrations/cloud-integrations/azure-iot-hub/" secondary>
                  <Message content={m.azureIoTHubIntegration} />
                </Link.DocLink>
                {' | '}
                <Link.DocLink
                  path="/integrations/cloud-integrations/azure-iot-hub/deployment/"
                  secondary
                >
                  <Message content={m.deploymentGuide} />
                </Link.DocLink>
              </div>
              <hr className={style.hRule} />
              <Message component="h3" content={m.credentials} />
              <DataSheet data={connectionData} />
              <hr className={style.hRule} />
              <Message component="h3" content={m.configuration} />
              <AzureIoTHubIntegrationForm />
            </Col>
          </Row>
        </Container>
      </ErrorView>
    </Require>
  )
}

export default AzureIoTHubIntegration
